import { cn } from "@utils/cn";
import { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { Label } from "./label";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
import { Info } from "@phosphor-icons/react";

interface InputV2Props extends InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string;
  label: string;
  labelInfo?: ReactNode;
}

const InputV2 = forwardRef<HTMLInputElement, InputV2Props>(
  ({ wrapperClassName, label, labelInfo, className, type, ...props }, ref) => {
    const isEmpty = !props.value;

    return (
      <div className={cn("", wrapperClassName, "relative")}>
        {label && (
          <div
            className={cn(
              "flex-center gap-1",
              "transition-all absolute left-3",
              "font-normal text-sm text-muted-foreground",
              "top-1/2",
              isEmpty && "-translate-y-1/2",
              !isEmpty && "top-2 text-xs",
            )}
          >
            <Label className={cn("")}>{label}</Label>

            {labelInfo && (
              <Tooltip>
                <TooltipTrigger>
                  <Info className="size-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>{labelInfo}</TooltipContent>
              </Tooltip>
            )}
          </div>
        )}
        <input
          type={type}
          className={cn(
            "transition-all",
            "flex h-14 w-full px-3 pb-2 pt-2 text-sm",
            "rounded-xl border border-input bg-transparent shadow-sm",
            "file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground",
            "placeholder:text-muted-foreground",
            "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
            "disabled:cursor-not-allowed disabled:opacity-50",
            !isEmpty && "pt-6",
            className,
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
InputV2.displayName = "InputV2";

export { InputV2, type InputV2Props };
