import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { ComponentProps } from "react";

function Skeleton({
  className,
  isLoading,
  children,
  contentContainerClassName,
  ...props
}: ComponentProps<typeof motion.div> & {
  isLoading?: boolean;
  contentContainerClassName?: string;
}) {
  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <motion.div
          key="skeleton"
          className={cn("animate-pulse rounded-md bg-muted w-full", className)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          {...props}
        />
      ) : (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={contentContainerClassName}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export { Skeleton };
