"use client";

import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";

import { cn } from "@utils/cn";
import { VariantProps, cva } from "class-variance-authority";
const avatarVariantsClasses = cva("relative flex h-10 w-10 shrink-0 overflow-hidden", {
  variants: {
    size: {
      xs: "h-4 w-4 text-tiny",
      small: "h-6 w-6 text-xs",
      medium: "h-8 w-8 text-sm",
      large: "h-10 w-10 text-base",
      xlarge: "h-12 w-12 text-base",
      xxlarge: "h-14 w-14 text-base",
    },
    bg: {
      blue: "bg-blue-500",
      yellow: "bg-amber-500",
      red: "bg-red-500",
      rose: "bg-rose-500",
      primary: "bg-primary text-primary-foreground",
      ai: "bg-purple-500"
    },
    border: {
      blue: "border-blue-400",
      yellow: "border-amber-400",
      red: "border-red-400",
      rose: "border-rose-400",
    }, // brighter than bg
    rounded: {
      full: "rounded-full",
      lg: "rounded-lg",
      md: "rounded-md",
      sm: "rounded-sm",
    },
    loading: {
      true: "animate-pulse",
    },
  },
  defaultVariants: {
    size: "medium",
    bg: "primary",
    rounded: "full",
  },
});
const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
  VariantProps<typeof avatarVariantsClasses>
>(({ className, size, bg, rounded, loading, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariantsClasses({ size, bg, rounded, loading }), className)}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, delayMs = 1000, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    data-fallback
    className={cn(
      "flex size-full items-center justify-center text-[1em] rounded-full bg-transparent text-white font-semibold",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

interface ComposedAvatarProps extends VariantProps<typeof avatarVariantsClasses> {
  initials: string;
  imageSrc?: string;
  online?: boolean;
}
function ComposedAvatar({
  size,
  imageSrc,
  initials,
  bg,
  rounded,
}: ComposedAvatarProps) {
  return (
    <Avatar size={size} rounded={rounded} bg={bg}>
      <AvatarImage src={imageSrc} alt={initials} />
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  );
}

export { Avatar, AvatarImage, AvatarFallback, ComposedAvatar };
