import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@utils/cn";

const alertVariantsClasses = cva(
  "relative z-5 w-full rounded-2xl border p-4 [&>svg~*]:pl-8 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        info: "border-blue-500 text-blue-900 bg-blue-100 [&>svg]:text-blue-900",
        destructive:
          "border-rose-500 text-rose-900 bg-rose-100 [&>svg]:text-rose-900",
        success:
          "border-green-500 text-green-900 bg-green-100 [&>svg]:text-green-900",
        warning:
          "border-amber-500 text-amber-900 bg-amber-100 [&>svg]:text-amber-900",
        ai: "border-purple-500 text-purple-900 bg-purple-100 [&>svg]:text-purple-900",
        'ai-outline': "border-purple-500 text-primary-foreground [&>svg]:text-primary-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertVariantsClasses>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariantsClasses({ variant }), className)}
    {...props}
  />
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "mb-1 text-sm font-semibold tracking-tight leading-none",
      className,
    )}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-xs [&_p]:leading-relaxed", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
