"use client";

import * as React from "react";

import { PopoverContentProps } from "@radix-ui/react-popover";
import { cn } from "@utils/cn";
import { useCommandState } from "cmdk";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

type Item<T extends string = string> = {
  value: T;
  label: string;
  keywords?: string[];
  description?: string;
  onSelect?: (value: T) => void;
  isSelected?: boolean;
  leftElement?: React.ReactElement;
  forceMount?: boolean;
};

type SelectedItem = Pick<Item, "value" | "label"> & { groupIndex: number };

type Group<T extends string = string> = {
  title?: string;
  items: Array<Item<T>>;
  onSelect?: (value: T) => void;
};

export type ComboboxProps = {
  className?: string;
  Trigger?: React.ReactElement;
  placeholder?: string;
  groups: Group[];
  nothingFoundText?: string;
  showNothingFoundText?: boolean;
  side?: PopoverContentProps["side"];
  align?: PopoverContentProps["align"];
  onSelect?: (item: SelectedItem) => void;
  closeOnSelect?: boolean;
  highlightIfSelection?: boolean;
  /**
   * Should be used along with `disableInternalState` to manually set the selected value.
   */
  externalSelected?: React.ReactNode;
  disableInternalState?: boolean;
  triggerProps?: {
    wobble?: boolean;
    className?: string;
  };
  controlled?: {
    open?: {
      open: boolean;
      setOpen: React.Dispatch<React.SetStateAction<boolean>>;
      shortcut?: string;
    };
    selection?: {
      selected: SelectedItem | null;
      setSelected: React.Dispatch<React.SetStateAction<SelectedItem | null>>;
    };
    search?: {
      search: string;
      setSearch: React.Dispatch<React.SetStateAction<string>>;
    };
  };
};

export function Combobox({
  className,
  Trigger,
  placeholder = "Select",
  groups,
  nothingFoundText = "Nothing found ¯\\_(ツ)_/¯",
  showNothingFoundText = true,
  side,
  align,
  onSelect,
  closeOnSelect = true,
  highlightIfSelection = false,
  externalSelected,
  disableInternalState = false,
  triggerProps,
  controlled,
}: ComboboxProps) {
  const [__open, __setOpen] = React.useState(false);
  const [open, setOpen] = controlled?.open
    ? [controlled.open.open, controlled.open.setOpen]
    : [__open, __setOpen];

  const [__selected, __setSelected] = React.useState<SelectedItem | null>(null);
  const [selected, setSelected] = controlled?.selection
    ? [controlled.selection.selected, controlled.selection.setSelected]
    : [__selected, __setSelected];

  const isSelectedItem = (v: string, groupIndex: number) => {
    return selected?.value === v && selected?.groupIndex === groupIndex;
  };

  const renderedValue: React.ReactNode = (() => {
    if (disableInternalState) {
      return externalSelected || placeholder;
    } else {
      return selected?.label || placeholder;
    }
  })();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {Trigger ? (
          Trigger
        ) : (
          <Button
            wobble={triggerProps?.wobble ?? true}
            variant={
              (selected || externalSelected) && highlightIfSelection
                ? "default"
                : "outline"
            }
            role="combobox"
            aria-expanded={open}
            className={cn(
              "justify-between rounded-xl",
              triggerProps?.className,
            )}
          >
            {renderedValue}
            <div className="flex items-center">
              {controlled?.open?.shortcut && (
                <kbd>{controlled?.open?.shortcut}</kbd>
              )}
              <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        className={cn("p-0 rounded-xl shadow-2xl", className)}
        side={side}
        align={align}
      >
        <Command className="rounded-xl" loop>
          <CommandInput
            placeholder={placeholder}
            className="h-9"
            value={controlled?.search?.search}
            onValueChange={controlled?.search?.setSearch}
          />
          <CommandList>
            {showNothingFoundText && (
              <CommandEmpty>{nothingFoundText}</CommandEmpty>
            )}
            {groups.map((group, i) => (
              <CommandGroup
                key={i}
                heading={group.title}
                // Must force mount group in order for force mounted items to show
                forceMount={group.items.some((i) => i.forceMount)}
              >
                {group.items.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    forceMount={item.forceMount}
                    // Add label to keywords in case `item.value` was a UUID
                    keywords={[
                      item.value,
                      item.label,
                      ...(group.title ? [group.title] : []),
                      ...(item.keywords ?? []),
                    ]}
                    onSelect={(_) => {
                      if (group.onSelect) group.onSelect(item.value);
                      else if (item.onSelect) item.onSelect(item.value);
                      else onSelect?.({ ...item, groupIndex: i });

                      if (!disableInternalState) {
                        isSelectedItem(item.value, i)
                          ? setSelected(null)
                          : setSelected({ ...item, groupIndex: i });
                      }

                      closeOnSelect && setOpen(false);
                    }}
                  >
                    <div className="flex flex-col flex-1 gap-2">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2 [&_svg]:size-4">
                          {item.leftElement && <>{item.leftElement}</>}
                          <p>{item.label}</p>
                        </div>
                        <div
                          className={cn(
                            "ml-auto size-2 rounded-full bg-foreground",
                            item.isSelected || isSelectedItem(item.value, i)
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                      </div>
                      {item.description && (
                        <p className="text-muted-foreground text-sm whitespace-pre-wrap break-words">
                          {item.description}
                        </p>
                      )}
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export {
  type SelectedItem as ComboboxSelectedItem,
  type Group as ComboboxGroup,
};
