"use client";

import { cn } from "@utils/cn";
import * as React from "react";
import { useImperativeHandle } from "react";
import { Textarea, TextareaProps } from "./textarea";

interface UseAutosizeTextAreaProps {
  textareaRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  minHeight?: number;
  maxHeight?: number;
  value: string;
}

export const useAutosizeTextArea = ({
  textareaRef,
  value,
  maxHeight = Number.MAX_SAFE_INTEGER,
  minHeight = 0,
}: UseAutosizeTextAreaProps) => {
  const [init, setInit] = React.useState(true);
  React.useEffect(() => {
    // We need to reset the height momentarily to get the correct scrollHeight for the textarea
    const offsetBorder = 0;
    const textarea = textareaRef.current;
    if (textarea) {
      if (init) {
        textarea.style.minHeight = `${minHeight + offsetBorder}px`;
        if (maxHeight > minHeight) {
          textarea.style.maxHeight = `${maxHeight}px`;
        }
        setInit(false);
      }
      textarea.style.height = `${minHeight + offsetBorder}px`;
      const scrollHeight = textarea.scrollHeight;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      if (scrollHeight > maxHeight) {
        textarea.style.height = `${maxHeight}px`;
      } else {
        textarea.style.height = `${scrollHeight + offsetBorder}px`;
      }
    }
  }, [textareaRef, value]);
};

export type AutosizeTextAreaRef = {
  textArea: HTMLTextAreaElement;
  maxHeight: number;
  minHeight: number;
};

type AutosizeTextAreaProps = TextareaProps & {
  maxHeight?: number;
  minHeight?: number;
  value: string | null | undefined;
  defaultValue?: string | null;
};

export const AutosizeTextarea = React.forwardRef<
  AutosizeTextAreaRef,
  AutosizeTextAreaProps
>(
  (
    {
      maxHeight = Number.MAX_SAFE_INTEGER,
      minHeight = 0,
      onChange,
      value,
      ...props
    }: AutosizeTextAreaProps,
    ref: React.Ref<AutosizeTextAreaRef>,
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);

    useAutosizeTextArea({
      textareaRef: textAreaRef,
      value: value || props.defaultValue || "",
      maxHeight,
      minHeight,
    });

    useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef.current?.focus(),
      maxHeight,
      minHeight,
    }));

    return (
      <Textarea
        // Default rows value is `2`, we want the default to be `1` then calculate if more lines are needed (through height)
        rows={1}
        {...props}
        value={value}
        ref={textAreaRef}
        onChange={(e) => {
          onChange?.(e);
        }}
      />
    );
  },
);
AutosizeTextarea.displayName = "AutosizeTextarea";
