import * as React from "react";

import { cn } from "@utils/cn";
import { cva, VariantProps } from "class-variance-authority";
const inputVariantsClasses = cva(
  "w-full m-0 bg-white data-[valid=false]:!border-destructive min-w-fit border block focus:outline-none border-border bg-background px-3 py-2 text-sm ring-offset-accent file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground placeholder:ttext-slate-400 focus-visible:outline-none focus-visible:border-primary transition-colors disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      h: {
        default: "h-10",
        tall: "h-11",
        xtall: "h-12",
        md: "h-10",
        sm: "h-9",
      },
      rounded: {
        none: "rounded-none",
        default: "rounded-xl",
      },
      shadow: {
        false: "",
        true: "shadow-sm",
      },
      text: {
        sm: "text-sm",
        base: "text-base",
        lg: "text-lg",
      },
    },
    defaultVariants: {
      h: "default",
      shadow: true,
      rounded: "default",
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariantsClasses> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, h, shadow, rounded, text, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariantsClasses({ h, shadow, rounded, text }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
