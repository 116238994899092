import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@utils/cn";
import { __config } from "../config";
import { Wobble, WobbleProps } from "./wobble";

export const buttonVariantsClasses = cva(
  "inline-flex shrink-0 items-center justify-center rounded-md text-sm font-medium ring-offset-background transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 whitespace-nowrap disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary shadow-sm text-primary-foreground",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input shadow-sm bg-background hover:text-accent-foreground",
        secondary:
          "bg-secondary text-muted-foreground shadow-[inset_0px_0px_0px_1px_rgba(0,0,0,0.1)] hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline",
        success: "bg-emerald-500 text-emerald-50 hover:bg-emerald-600",
        ai: "bg-purple-500 text-purple-50 hover:bg-purple-600",
        destructiveOutline:
          "border-destructive border text-destructive hover:bg-destructive/20",
      },
      size: {
        xs: "py-1 rounded-sm px-2",
        sm: "h-9 rounded-md px-2",
        md: "h-9 rounded-md px-3",
        lg: "h-10 rounded-md px-4",
        icon: "w-fit h-fit p-2",
        'icon-sm': "w-fit h-fit p-1",
        fluid: "w-full h-10",
        fit: "w-fit h-fit p-1.5",
        selfless: "w-fit h-fit !p-0"
      },
      rounded: {
        full: "rounded-full",
        lg: "rounded-lg",
        md: "rounded-md",
        sm: "rounded-sm",
        none: "rounded-none",
        xl: "rounded-xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
      rounded: "xl",
    },
  },
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariantsClasses> {
  asChild?: boolean;
  loading?: boolean;
  wobble?: boolean;
  wobbleProps?: Partial<WobbleProps>;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      rounded,
      asChild = false,
      type = "button",
      loading,
      wobble,
      wobbleProps,
      ...props
    },
    ref,
  ) => {
    const isWobbleEnabled: boolean = wobble ?? __config.wobble.button;
    const Comp = asChild ? Slot : "button";
    const button = (
      <Comp
        className={cn(
          buttonVariantsClasses({ variant, size, rounded, className }),
          loading && "loading-el",
        )}
        ref={ref}
        type={type}
        {...props}
      />
    );

    return isWobbleEnabled ? <Wobble ref={ref} {...wobbleProps}>{button}</Wobble> : button;
  },
);
Button.displayName = "Button";

export { Button, buttonVariantsClasses as buttonVariants };
